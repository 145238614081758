import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/'
	},
	{
		path: '/pc',
		name: 'Home',
		component: () => import('../views/pc/Home.vue')
	},
	{
		path: '/pc/copy',
		name: 'copy',
		component: () => import('../views/pc/copy.vue')
	},
	{
		path: '/pc/detailsinfo',
		name: 'detailsinfo',
		component: () => import('../views/pc/detailsinfo.vue')
	},
	{
		path: '/h5',
		name: 'Home',
		component: () =>
			import('../views/h5/Home.vue')
	},
	{
		path: '/h5/copy',
		name: 'copy',
		component: () => import('../views/h5/copy.vue')
	},
	{
		path: '/h5/details',
		name: 'details',
		component: () =>
			import('../views/h5/details.vue')
	},

]

function isPhone() {
	if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)))
		return true;
	return false;
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	var isMobile = isPhone();
	console.log(isMobile, to.path)
	// pc 判断
	if(!isMobile && (to.path == '/' || to.path == '/h5/copy' || to.path == '/h5/details')) {
		next("/pc");
		return;
	}
	if(!isMobile && (to.path == '/pc/copy' || to.path == '/pc/')) {
		next();
		return;
	}
	if(!isMobile && to.path == '/h5') {
		next("/pc");
		return;
	}
	// h5判断

	if(isMobile && (to.path == '/' || to.path == '/pc')) {
		next("/h5");
		return;
	}
	if(isMobile && to.path == '/h5/copy') {
		next();
		return;
	}
	if(to.path == '/pc' || to.path == '/h5/details') {
		next();
		return;
	}
	// 公共判断
	if(to.path == '/pc' || to.path == '/h5') {
		next();
		return;
	}
	if(isMobile && to.path == '/pc') {
		next("/h5");
		return;
	}
	if(isMobile && to.path != '/h5') {
		next("/h5");
		return;
	}
	
})

export default router