;
(function(document, window) {
	var html = document.documentElement;
	var hwidth = html.getBoundingClientRect().width;
	html.style.fontSize = (hwidth / 30 + "px") //1rem 在ipone8下font-size=10px，即 375 / 37.5 = 10px
	window.onresize = function() {
		hwidth = html.getBoundingClientRect().width;
		html.style.fontSize = (hwidth / 30 + "px") //1rem 在ipone8下font-size=10px，即 375 / 37.5 = 10px
	};
	html.addEventListener('touchstart', function(e) {
		if(e.touches.length > 1) {
			e.preventDefault();
		}
	}, false);
})(document, window);